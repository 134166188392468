import { createTheme, responsiveFontSizes } from "@mui/material";
import { indigo, teal } from "@mui/material/colors";
let theme = createTheme({
    typography: {
        fontFamily: ['"Kanit"', "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', '"Roboto"', '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"', '"Segoe UI Emoji"', '"Segoe UI Symbol"'].join(","),
        fontSize: 18,
    },
    palette: {
        primary: {
            main: indigo[500],
        },
        secondary: {
            main: teal[100],
        },
        background: {
            default: "#293241",
        },
    },
    components: {
        MuiCard: {
            styleOverrides: {},
        },
    },
});

theme = responsiveFontSizes(theme);

export default theme;
