import { lazy } from "react";
import { Route, Routes } from "react-router-dom";

let Main = lazy(() => import("../Pages/Main"));
let Work = lazy(() => import("../Pages/Work"));

export const MainRoutes = () => (
    <Routes>
        <Route path="" element={<Main />} />
        <Route path="work" element={<Work />} />
    </Routes>
);
export default MainRoutes;
