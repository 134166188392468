import esTranslation from "./es";
import enTranslation from "./en";

const translations = {
    en: {
        translation: enTranslation,
    },
    es: {
        translation: esTranslation,
    },
};

export default translations;
