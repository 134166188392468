export const translation = {
    underConstructionLabel: "Sitio en construción",
    "About Me": "Sobre Mí",
    Skills: "Habilidades",
    Projects: "Proyectos",
    Contact: "Contacto",
    "Contact Me": "Contactame",
    "Full Stack Developer": "Desarrollador Full Stack",
    "My Skills": "Mis Habilidades",
    "All Rights Reserved": "Todos los derechos reservados",
    "I'm {{name}}": "Soy {{name}}",
    "Banchelor in Computer Science": "Licenciado en Ciencias de la Computación",
    "I'm Full Stack Developer, and I work in the whole process of creating applications, from the design to the final release. I develop web and mobile applications, front end and back end sides, using last technologies and cloud services like Azure and AWS.": "Soy Desarrollador Full Stack y trabajo en todo el proceso de creación de aplicaciones, desde el diseño hasta la versión final. Desarrollo aplicaciones web y móviles, front-end y back-end, utilizando las últimas tecnologías servicios en la nube como Azure y AWS",
    Birdday: "Fecha de Nacimiento",
    Age: "Edad",
    Country: "País",
    Address: "Dirección",
    Email: "Correo Electrónico",
    "Contact from Website": "Contacto desde el sitio web",
    "Hi Carlos, I would like to contact you.": "Hola Carlos, me gustaría contactarte.",
    Name: "Nombre",
    Message: "Mensaje",
    Send: "Enviar",
};

export default translation;
