import "./App.css";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { MainRoutes } from "./Routes";
import { useTranslation } from "react-i18next";

const App = () => {
    const { i18n } = useTranslation();

    if (!Boolean(i18n.language)) {
        i18n.init({ lng: "en" });
    }

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Navigate to="/en" />} />
                <Route path="/:lang/*" element={<MainRoutes />} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
